import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { RollcallModel } from "@/models";
import { Common } from "../Common";

export class RollcallForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) time = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) sid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) clid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) tid = '';
    @MaxLength(100, { message: "最多輸入100字" }) note = '';
}

@Component<AdminRollcallAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminRollcallAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private rollcallForm = new RollcallForm();
    private cityList: { [key: string]: any} = {};
    private schoolList: { [key: string]: any} = {};
    private classList: object = {};
    private teacherList: object = {};

    private identity = window.localStorage.getItem('admin_identity') as string;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private errorMsgMap: { [key: string]: string } = {
        time: '',
        sid: '',
        clid: '',
        tid: '',
        note: '',
    };

    public async created() {
        if (this.identity == "1") {
            this.cityList = await Common.getSchoolCity() as object; //取得有分校之縣市
        } else {
            const sid = window.localStorage.getItem('admin_sid') as string;
            this.cityList = await Common.getCity(sid) as object; //取得該分校之縣市

            const school = window.localStorage.getItem('admin_school') as string;;
            this.schoolList[0] =  {
                sid: sid,
                name: school,
            };
        }
    }

    //取得該縣市之分校
    private async getSchool(cid: string) {
        if (this.identity == "1") { //權限為分校者，不連動分校列表
            this.rollcallForm.sid = '';
            this.rollcallForm.clid = '';
            this.rollcallForm.tid = '';

            this.schoolList = await Common.getSchool(cid) as object;
            this.classList = [];
            this.teacherList = [];
        }
    }

    // 取得 該分校的班級列表
    private async getClass(sid: string) {
        this.rollcallForm.clid = '';
        this.rollcallForm.tid = '';

        this.classList = await Common.getClass(1, sid, 0) as object;
        this.teacherList = [];
    }

    // 取得 該班級的老師列表
    private async getTeacher(clid: string) {
        this.rollcallForm.tid = '';
        this.teacherList = await Common.getTeacher(1, clid, 0) as object;
    }

    private validateAndSubmit() {
        validate(this.rollcallForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        time: '',
                        sid: '',
                        clid: '',
                        tid: '',
                        note: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = { //要傳入的form參數
                        time: this.rollcallForm.time,
                        sid: this.rollcallForm.sid,
                        clid: this.rollcallForm.clid,
                        tid: this.rollcallForm.tid,
                        note: this.rollcallForm.note,
                        token: this.token,
                    }
                    this.errNo = await RollcallModel.add(item);
                    if (this.errNo === ErrorCode.InvalidToken) {
                        Common.logout();
                        return;
                    }
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/rollcall");
        }
    }
}
